.App {
    position: relative;
}

.App .InfoText {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 1.25rem;
    text-align: center;
    font-weight: 500;
    color: rgb(83, 83, 83);
}

.App .InfoText:first-line {
    color: black;
}

.App .TitleText {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 0.25rem;
}

.App .realTime {
    position: relative;
    top: -1rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 1.25rem;
    font-weight: 500;
    color: rgb(30, 30, 30);
}

.App .footer {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: grey;
    font-weight: 600;
    position: fixed;
    bottom: 1rem;
    text-align: center;
    width: 100%;
}

.hidden {
    visibility: hidden;
}

.noBG {
    background-color: white !important;
}