.DrawingCanvas {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 45vh;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
}

.DrawingCanvas .canvas {
  width: 40vh;
  height: 40vh;
  border: 1px solid black;
  cursor: crosshair;
}

.DrawingCanvas .TFLogo {
  height: 30vh;
  aspect-ratio: auto;
  margin-left: -1rem;
}

.DrawingCanvas .Arrow {
  height: min(8vh, 2.5vw);
  aspect-ratio: auto;
}

.DrawingCanvas .Section2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
}

.DrawingCanvas .canvasSmall {
  width: 12vh;
  height: 12vh;
  border: 1px solid black;
  cursor: not-allowed;
  justify-self: center;
}

.DrawingCanvas .Section2 button {
  outline: none;
  cursor: pointer;
  background-color: rgb(255, 51, 0);
  color: white;
  border: none;
  border-radius: 26px;
  width: 10vh;
  height: 2rem;
  position: relative;
  top: -2rem;
}

.DrawingCanvas .CanvasCover {
  background-color: rgb(220, 220, 220);
  width: 40vh;
  height: 40vh;
}

.DrawingCanvas .CanvasCover .CoverText {
  position: absolute;
  width: 40vh;
  height: 40vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Georgia, 'Times New Roman', Times, serif;
  cursor: pointer;
}

.DrawingCanvas .CanvasCover .CoverText .important {
  color: rgb(51, 0, 255);
}