@font-face {
  font-family: handWritten;
  src: url('../assets/HandwrittenTF.ttf');
}

.OutputChart {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1vh;
  width: calc(60% + 1rem);
  height: 28vh;
}